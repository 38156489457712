/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";

// @font-face {
// 	font-family: "Vega";
// 	src: url("assets/fonts/Vega-Regular.ttf") format("truetype");
// 	font-weight: normal;
// 	font-styont-familyle: normal;
// }

.custom-popover-width {
	--width: 30%;
	// background-color: transparent;
	// --box-shadow: none; /* Remove shadow */
	@media screen and (max-width: 600px) {
		--width: 80%;
	}
}
// .custom-popover-width .popover-content {
// 	background-color: transparent; /* Adjust this selector as needed */
// 	--box-shadow: none; /* Remove shadow */
// }

// ::ng-deep .custom-popover-width {
// 	--width: 300px;
// 	background-color: transparent; /* Or any other color */
// }
